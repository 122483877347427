.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 1px;
  border-color: #edebe9;
}

.k-tabstrip-content {
  > div {
    width: 100%;
  }
}
