@import '../../../styles/variables';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.logo {
  height: 40px;
  pointer-events: none;
  margin-right: 25px;
}

button.link,
a.link {
  color: rgba(255, 255, 255, 0.55);

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
}

.menuItems {
  a.link {
    margin-right: 20px;
    text-decoration: none;
  }
}

:local .menuItems :global(a.active) {
  color: $textPrimaryColor;
}

.menuIcon {
  position: relative;
  top: 4px;
}

:global {
  :local(.appBar) {
    width: 100%;
    background-color: #223b54;

    ul {
      font-size: 14px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
    }

    li {
      margin: 0 10px;
    }

    li:hover {
      cursor: pointer;
      color: #84cef1;
    }

    a {
      text-decoration: none;
    }

    a.active {
      color: $textPrimaryColor;
    }

    .k-split-button-arrow {
      .k-button-icon {
        &::after {
          all: initial;
        }
      }
    }
  }
}

.dropdownIcon {
  margin-right: 10px;

  span:first-child {
    position: absolute;
    right: 1px;
    bottom: 0.7vh;
  }
}

:global {
  :local(.dropdownPopup) {
    & .k-item.k-focus {
      & .k-link {
        outline-style: none;
      }
    }
  }
}

:global {
  :local(.customersDropdown) {
    &.k-picker {
      width: 200px;
    }
  }
}
