.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;

  // > * {
  //   padding-left: 70px;
  //   padding-right: 70px;
  // }
}
