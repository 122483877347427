@import '../../../styles/variables';

:global {
  :local(.customersDropdown) {
    &.k-picker {
      width: 200px;
      border: 1px solid $borderColor;
    }
  }
}
