.k-breadcrumb-link:focus,
.k-breadcrumb-link.k-focus {
  background-color: transparent;
  box-shadow: none;
}

.k-breadcrumb-link:active,
.k-breadcrumb-link.k-active,
.k-breadcrumb-link:hover,
.k-breadcrumb-link.k-hover,
.k-breadcrumb-link:focus:hover,
.k-breadcrumb-link:focus.k-hover,
.k-breadcrumb-link.k-focus:hover,
.k-breadcrumb-link.k-focus.k-hover {
  background-color: transparent;
}
