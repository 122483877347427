.form-card {
  padding: 30px 30px 30px 30px;

  .actions {
    margin-top: 60px;
  }

  .list-item-link {
    margin-right: 8px;
  }

  .k-form-field-wrap {
    margin-bottom: 0px;
  }

  .k-form-field .k-label {
    font-size: 14px;
    font-weight: 400;
  }
}
