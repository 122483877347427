.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &.backdrop {
        background-color: rgba(10, 10, 10, 0.1);
    }
}

.spinner,
.spinner:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}

.spinner {
    display: flex;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.3em solid rgba(255, 255, 255, 0.2);
    border-right: 0.3em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
    border-left: 0.3em solid #797979;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loaderAnimation 1.1s infinite linear;
    animation: loaderAnimation 1.1s infinite linear;
}

@-webkit-keyframes loaderAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loaderAnimation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}