.dropdownlist {
  border-color: initial;
  color: initial;

  :focus,
  .k-focus,
  :focus-within &::after {
    border: 0;
  }
}
