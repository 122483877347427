.table {
  caption-side: bottom;
  border-collapse: collapse;

  thead tr:last-child {
    border-bottom: 1px solid #d2d0ce;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f3f2f1;
  }
}

.table-sm {
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
  }
}
