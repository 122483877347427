.breadcrumbs {
  background-color: initial;
  border-bottom: 1px solid #edebe9;

  li:first-child {
    :global(.k-breadcrumb-link) {
      padding-left: 0;
    }
  }

  :global {
    .k-breadcrumb-link > .k-image,
    .k-breadcrumb-icontext-link .k-icon,
    .k-breadcrumb-icontext-link .k-svg-icon {
      margin-right: 0;
    }
  }
}
