.page {
  padding: 0;
  position: relative;
  width: 100%;

  .page-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: $textColor;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  > .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.form-page {
    padding-left: 80px;
    padding-right: 80px;
  }
}
